class Establecimiento{
    constructor(
        IdEstablecimiento,
        Titulo,
        Ciudad,
        Pais,
        IdPais,
        IdCiudad,
        EdadNino,
        Catalogacion,
        Longitud,
        Latitud,
        Logo,
        Foto,
        Direccion,
        Servicios,
        ServiciosHab,
        Incluye,
        NoIncluye,
        Restricciones,
        SistemaServicios,
        Contactos,
        ContactosCentral,
        Galeria,
        Descripcion,
        PrecioSinImpuestos,
        PrecioConImpuestos,
        Impuestos,
        Rack,
        Ofertas,
        Recomendados,
        Favorito,
        Calificacion,
    ){
        this.Establecimiento=IdEstablecimiento
        this.Titulo=Titulo
        this.Ciudad=Ciudad
        this.Pais=Pais
        this.IdPais=IdPais
        this.IdCiudad=IdCiudad
        this.EdadNino=EdadNino
        this.Catalogacion=Catalogacion
        this.Longitud=Longitud
        this.Latitud=Latitud
        this.Logo=Logo
        this.Foto=Foto
        this.Direccion=Direccion
        this.Servicios=Servicios
        this.ServiciosHab=ServiciosHab
        this.Incluye=Incluye
        this.NoIncluye=NoIncluye
        this.Restricciones=Restricciones
        this.SistemaServicios=SistemaServicios
        this.Contactos=Contactos
        this.ContactosCentral=ContactosCentral
        this.Galeria=Galeria
        this.Descripcion=Descripcion
        this.PrecioSinImpuestos=PrecioSinImpuestos
        this.PrecioConImpuestos=PrecioConImpuestos
        this.Impuestos=Impuestos
        this.Rack=Rack
        this.Ofertas=Ofertas
        this.Recomendados=Recomendados
        this.Favorito=Favorito
        this.Calificacion=Calificacion
    }
}

export default Establecimiento;
class OfertaInicio{
    constructor(IdOferta, IdEstablecimiento, Foto, Precio, Lugar, Establecimiento, Titulo, Noches, Dias, Adultos, Ninos, Catalogacion){
        this.IdOferta = IdOferta;
        this.IdEstablecimiento=IdEstablecimiento;
        this.Foto=Foto;
        this.Precio=Precio;
        this.Lugar=Lugar;
        this.Establecimiento=Establecimiento;
        this.Titulo=Titulo;
        this.Noches=Noches;
        this.Dias=Dias;
        this.Adultos=Adultos;
        this.Ninos=Ninos;
        this.Catalogacion=Catalogacion
    }
}

export default OfertaInicio
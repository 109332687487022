class Contactos{
    constructor(
        Whatsapp,
        Email,
        Telefono,
        Web
    ){
        this.Whatsapp=Whatsapp
        this.Email=Email
        this.Telefono=Telefono
        this.Web=Web
    }
}
export default Contactos;
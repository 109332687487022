class Favorito{
    constructor(
        Id,
        Titulo,
        Catalogacion,
        Direccion,
        Latitud,
        Longitud,
        Foto
    ){
        this.Id=Id;
        this.Titulo=Titulo;
        this.Catalogacion=Catalogacion;
        this.Direccion=Direccion;
        this.Latitud=Latitud;
        this.Longitud=Longitud;
        this.Foto=Foto;
    }
}

export default Favorito;
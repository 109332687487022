class Oferta{
    constructor(
        Id,
        IdLugar,
        EstadoBusqueda,
        IdOferta,
        Habitaciones,
        IdEstablecimiento,
        AplicaEn,
        TituloOferta,
        Ninos,
        Adultos,
        Dias,
        Noches,
        Certificado,
        Detalle,
        Ganga,
        TextoGanga,
        Rack,
        Final,
        Base,
        Ahorro,
        FinalSinImpuestos,
        Impuestos,
        PorcentajeAhorro,
        Ciudad,
        Provincia,
        Favorito,
        FotoPrincipal,
        EstiloBeneficio,
        IdBeneficio,
        ColorBeneficio,
        Localidad,
        Incluye,
        Whatsapp,
        Telefono,
        Celular,
        Email,
        Web,
        Beneficio,
        NumOfertas,
        SistemaServicios,
        Restricciones,
        NoIncluye,
        Acomodacion,
    ){
        this.Id=Id;
        this.IdLugar=IdLugar;
        this.EstadoBusqueda=EstadoBusqueda;
        this.IdOferta=IdOferta;
        this.Habitaciones=Habitaciones;
        this.IdEstablecimiento=IdEstablecimiento;
        this.AplicaEn=AplicaEn;
        this.TituloOferta=TituloOferta;
        this.Ninos=Ninos;
        this.Adultos=Adultos;
        this.Dias=Dias;
        this.Noches=Noches;
        this.Certificado=Certificado;
        this.Detalle=Detalle;
        this.Ganga=Ganga;
        this.TextoGanga=TextoGanga;
        this.Rack=Rack;
        this.Final=Final;
        this.Base=Base;
        this.Ahorro=Ahorro;
        this.FinalSinImpuestos=FinalSinImpuestos;
        this.Impuestos=Impuestos;
        this.PorcentajeAhorro=PorcentajeAhorro;
        this.Ciudad=Ciudad;
        this.Provincia=Provincia;
        this.Favorito=Favorito;
        this.FotoPrincipal=FotoPrincipal;
        this.EstiloBeneficio=EstiloBeneficio;
        this.IdBeneficio=IdBeneficio;
        this.ColorBeneficio=ColorBeneficio;
        this.Localidad=Localidad;
        this.Incluye=Incluye;
        this.Whatsapp=Whatsapp;
        this.Telefono=Telefono;
        this.Celular=Celular;
        this.Email=Email;
        this.Web=Web;
        this.Beneficio=Beneficio;
        this.NumOfertas=NumOfertas;
        this.SistemaServicios=SistemaServicios;
        this.Restricciones=Restricciones;
        this.NoIncluye=NoIncluye;
        this.Acomodacion=Acomodacion;
    }
}

export default Oferta;
class Detalle{
    constructor(
        Titulo,
        Valor,
        Icono
    ){
        this.Titulo=Titulo;
        this.Valor=Valor;
        this.Icono=Icono;
    }
}
export default Detalle;